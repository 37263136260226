declare global {
  export interface Window {
    // This definition aligns with `src/assests/env.ts` and it's template
    env: {
      dd: {
        service: string;
        env: string;
        version: string;
        sampleRate: string;
      };
      branchIo: {
        token: string;
      };
    };
  }
}

// These must be lazy as otherwise we break the Karma tests
export const ddConfig = () => ({
  ...window.env.dd,
  sampleRate: Number(window.env.dd.sampleRate),
});

export const branchIoConfig = () => window.env.branchIo;
