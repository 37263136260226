import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'runtypes';
import { Static } from 'runtypes';

import { DepositWithdrawalFee, FeeCalculation, GatewayFeeType } from '../entities/fees';
import { URLS } from './app.constant';
import { Enum } from '../utils/runtypes';

const ApiFeeCalculation = Enum(FeeCalculation);
const ApiGatewayFeeType = Enum(GatewayFeeType);

const ApiWithdrawalFee = R.Record({
  id: R.Record({
    gatewayFeeTypeEn: ApiGatewayFeeType,
  }),
  feeCalculationEn: ApiFeeCalculation,
  fixedFeeAmt: R.Number.nullable(),
  percentageFeeAmt: R.Number.nullable(),
});
export type ApiWithdrawalFee = Static<typeof ApiWithdrawalFee>;

const ApiFeeTier = R.Record({
  name: R.String,
  requiredZoomTokens: R.Number,
  simpleTradeFeePct: R.Number.nullable(),
});
export type FeeTier = Static<typeof ApiFeeTier>;

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  constructor(private http: HttpClient) {}

  public getDepositWithdrawalFee(currency: string, type: GatewayFeeType, network?: string): Observable<DepositWithdrawalFee | null> {
    return this.http.get(URLS.depositWithdrawalFeeUrl(currency, type, network)).pipe(
      map(ApiWithdrawalFee.nullable().check),
      map((raw) => (raw ? DepositWithdrawalFee.build(raw) : null))
    );
  }

  public getFeeTiers(): Observable<Array<FeeTier>> {
    return this.http.get(URLS.feeTiers).pipe(map(R.Array(ApiFeeTier).check));
  }
}
