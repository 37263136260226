import { GatewayFeeType } from '../entities/fees';

export const baseUrl = '/api/v1/private';
export const basePublicUrl = '/api/v1/public';
export const baseAuthUrl = baseUrl + '/api-auth';
export const baseNotificationUrl = baseUrl + '/api-notification';
export const baseConfigUrl = baseUrl + '/config-api';
export const baseCryptoUrl = baseUrl + '/crypto-api';
export const basePartnerPaymentUrl = baseUrl + '/payment-api';
export const baseEarnUrl = baseUrl + '/earn-api';
export const baseHistoryUrl = baseUrl + '/history-api';
export const baseOrderUrl = baseUrl + '/order-api';
const baseAccountUrl = baseUrl + '/account-api';

// TODO move the services which use these URLs into core
export const BaseURLS = {
  orderNotificationSocket: baseNotificationUrl + '/notification-websocket',
};

export const URLS = {
  signIn: baseAuthUrl + '/login',
  logout: baseAuthUrl + '/logout',
  submitGoogleOTP: baseAuthUrl + '/match/auth_otp',
  refreshToken: baseAuthUrl + '/refreshToken',
  reloadLoginResponse: baseAuthUrl + '/loginresponse/reload',
  sendOTP: baseAuthUrl + '/otp',
  checkReferralToken: baseAuthUrl + '/check/referral/token',
  signUp: baseAuthUrl + '/register',
  signUpWithOtp: baseAuthUrl + '/register/otp',
  signupEmailOtpVerification: baseAuthUrl + '/verify_otp',
  resendSignupEmailOtp: baseAuthUrl + '/otp/token',
  googleAuthStatus: baseAuthUrl + '/check_auth_status',
  googleAuthEnable: baseAuthUrl + '/auth_key/set',
  googleAuthView: baseAuthUrl + '/auth_key/view',
  googleAuthStartReset: baseAuthUrl + '/auth_key/reset/start',
  googleAuthConfirmReset: baseAuthUrl + '/auth_key/reset/confirm',
  currenciesUrl: baseConfigUrl + '/currencies',
  instrumentsUrl: baseConfigUrl + '/instruments',
  liquidityProvidersUrl: baseConfigUrl + '/enabled_lp_names',
  marketDataStreamUrl: basePublicUrl + '/market/data/stream',
  depositWithdrawalFeeUrl: (ccy: string, type: GatewayFeeType, network?: string) =>
    network
      ? baseConfigUrl + `/deposit_withdrawal/fee/${ccy}/${type}?network=${network}`
      : baseConfigUrl + `/deposit_withdrawal/fee/${ccy}/${type}`,
  getWalletAddressUrl: baseCryptoUrl + '/address/{ccy}',
  createWalletAddressUrl: baseCryptoUrl + '/address/{ccy}/generate',
  getCurrentEarnInterestRates: baseEarnUrl + '/rates/current',
  earnTransfer: baseEarnUrl + '/transfer',
  placeOrder: baseOrderUrl + '/create',
  placeOCO: baseOrderUrl + '/oco/create',
  validateProfileUrl: baseAuthUrl + '/user/profile/validate',
  sendPhoneVerificationCodeUrl: baseAuthUrl + '/user/send/sms/verification',
  suggestHandle: baseAccountUrl + '/suggest/handle/',
  submitSsn: baseAuthUrl + '/user/kyc/ssn-suffix',
  profileUrl: baseAuthUrl + '/user/profile',
  limitedProfileUrl: baseAuthUrl + '/user/profile/limited',
  getCountryList: (activeOnly: boolean) => baseAuthUrl + `/default_country_code_list?activeOnly=${activeOnly}`,
  countryStateListUrl: baseAuthUrl + '/get/states/:countryId',
  tradeDefaults: baseAccountUrl + '/order/defaults',
  ledgerList: baseAccountUrl + '/ledgers',
  // tabapay card endpoints
  bankCardMetadataUrl: baseUrl + '/bankcard3ds-api/deposit/metadata',
  bankCardListUrl: baseUrl + '/bankcard3ds-api/cards',
  bankCardDeleteUrl: (binNumber: string, last4: string) => baseUrl + `/bankcard3ds-api/card/delete/${binNumber}/${last4}`,
  bankCardInit3DSUrl: baseUrl + '/bankcard3ds-api/init/web',
  bankCardLookup3DSUrl: baseUrl + '/bankcard3ds-api/lookup/web',
  bankCardAuthenticate3DSUrl: baseUrl + '/bankcard3ds-api/authenticate',
  bankCardWithdraw3DSUrl: baseUrl + '/bankcard3ds-api/withdraw',
  bankCardInitPayment3DSUrl: basePartnerPaymentUrl + '/confirm/card3ds/init',
  bankCardLookupPayment3DSUrl: basePartnerPaymentUrl + '/confirm/card3ds/lookup',
  bankCardAuthenticatePayment3DSUrl: basePartnerPaymentUrl + '/confirm/card3ds/authenticate',
  feeTiers: baseConfigUrl + '/fee_tiers?zoomDiscountOnly=false',
};
